import React, { useEffect, useState } from "react";
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

function CoachDashboard() {
    const [athleteData, setAthleteData] = useState([]);
    const [searchParams, setSearchParams] = useState({
        athletes: [],
        questions: [],
        startDate: undefined,
        endDate: undefined,
    });
    const [sortBy, setSortBy] = useState("date");

    const [availableAthletes, setAvailableAthletes] = useState([]);

    const availableQuestions = [
        { name: "valenceDay", displayName: "Tagesgefühl", valueTexts: { "-5": "sehr schlecht", 0: "neutral", 5: "sehr gut" } },
        {
            name: "readiness",
            displayName: "Bereitschaft",
            valueTexts: { 0: "überhaupt nicht bereit", 10: "maximal bereit" },
        },

        { name: "rpeSession", displayName: "Session-RPE", valueTexts: { 1: "sehr niedrig", 10: "sehr hoch" } },

        { name: "valenceSession", displayName: "Sessiongefühl", valueTexts: { "-5": "sehr negativ", 0: "neutral", 5: "sehr positiv" } },
        { name: "notes", displayName: "Notizen" },
    ];

    useEffect(() => {
        loadAvailableAthletes();
    }, []);

    const search = async () => {
        try {
            let queryParams = "";
            if (searchParams.athletes.length === 0) {
                return;
            }
            for (const athlete of searchParams.athletes) {
                queryParams += "athletes[]=" + athlete + "&";
            }
            for (const question of searchParams.questions) {
                queryParams += "questions[]=" + question + "&";
            }
            if (searchParams.startDate) {
                queryParams += "startDate=" + searchParams.startDate + "&";
            }
            if (searchParams.endDate) {
                queryParams += "endDate=" + searchParams.endDate + "&";
            }
            const url = `/api/search?${queryParams}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            let data = await response.json();

            data = data.sort((a, b) => {
                if (a[sortBy] < b[sortBy]) return -1;
                if (a[sortBy] > b[sortBy]) return 1;
                return 0;
            });
            setAthleteData(data);
        } catch (error) {
            console.error("Error fetching questionnaire:", error);
        }
    };

    const loadAvailableAthletes = async () => {
        try {
            const response = await fetch(`/api/users`);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setAvailableAthletes(data);
        } catch (error) {
            console.error("Error fetching questionnaire:", error);
        }
    };

    const handleInputChange = (e) => {
        let { name, value, checked } = e.target;

        if (name === "startDate" || name === "endDate") {
            setSearchParams({ ...searchParams, [name]: value });
        } else {
            setSearchParams({
                ...searchParams,
                [name]: checked ? [...searchParams[name], value] : searchParams[name].filter((item) => item !== value),
            });
        }
    };

    const handleLabelClick = (type, value) => {
        const isChecked = searchParams[type].includes(value);
        setSearchParams({
            ...searchParams,
            [type]: isChecked ? searchParams[type].filter((item) => item !== value) : [...searchParams[type], value],
        });
    };

    const handleSelectAll = (type) => {
        setSearchParams({ ...searchParams, [type]: [...availableItems(type)] });
    };

    const handleSelectNone = (type) => {
        setSearchParams({ ...searchParams, [type]: [] });
    };

    const handleToggleDate = (name) => {
        setSearchParams({
            ...searchParams,
            [name]: searchParams[name] ? undefined : new Date().toISOString().split("T")[0],
        });
    };

    const availableItems = (type) => {
        switch (type) {
            case "athletes":
                return availableAthletes.map((athlete) => athlete._id);
            case "questions":
                return availableQuestions.map((question) => question.name);
            default:
                return [];
        }
    };

    useEffect(() => {
        // Create a copy of the athleteData array
        const sortedData = [...athleteData];

        // Sort the copy
        sortedData.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) return -1;
            if (a[sortBy] > b[sortBy]) return 1;
            return 0;
        });

        // Update the state with the sorted copy
        setAthleteData(sortedData);
    }, [sortBy]);

    function handleSortByChange(e) {
        const { name } = e.target;
        setSortBy(name);
    }

    function displayChart() {
        let chartData = {};

        // Prepare chart data
        for (const entry of athleteData) {
            for (const selectedMetric of searchParams.questions) {
                const question = availableQuestions.find((q) => q.name === selectedMetric);
                if (!question) continue; // Skip if no matching question

                const key = `U_${entry.userId.name}-Q_${selectedMetric}`;
                const label = `${entry.userId.name} - ${question.displayName}`;
                const value = entry[selectedMetric];

                if (!chartData[key]) {
                    chartData[key] = {
                        data: [],
                        label: label,
                    };
                }

                // Use 'x' for date and 'y' for metric value
                chartData[key].data.push({ x: new Date(entry.date).getTime(), y: value });
            }
        }

        // Custom Tooltip
        const CustomTooltip = ({ active, label, payload }) => {
            if (active && payload && payload.length) {
                return (
                    <div className="">
                        <p className={"font-bold"}>{new Date(label).toLocaleDateString("de-DE")}</p>
                        {payload.map((entry, index) => (
                            <div key={index}>
                                <span style={{ color: entry.color }}>{entry.name}</span>
                                <span className={"ml-2"}>{entry.value}</span>
                            </div>
                        ))}
                    </div>
                );
            }
            return null;
        };

        // Render Chart
        return (
            <div className="bg-accent border rounded-2xl p-4 mt-4 aspect-video w-full">
                <h1 className="text-2xl text-secondary font-bold mb-3">Chart</h1>
                <ResponsiveContainer
                    width="100%"
                    height="100%"
                    className={"min-h-screen md:min-h-0"}>
                    <ComposedChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="x"
                            type={"number"}
                            domain={["auto", "auto"]}
                            scale={"time"}
                            tickFormatter={(time) =>
                                new Date(time).toLocaleDateString("de-DE", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })
                            }
                        />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        {Object.keys(chartData).map((key) => (
                            <Line
                                key={key}
                                dataKey="y"
                                data={chartData[key].data}
                                type="monotone"
                                stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} // Random color
                                name={chartData[key].label}
                            />
                        ))}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }

    function displayTable() {
        return athleteData.map((entry, index) => (
            <div
                key={index}
                className={"bg-accent rounded-xl p-2 m-1 flex flex-wrap"}>
                <div className={"flex flex-col mr-5 border border-secondary border-solid mx-2 p-2 rounded-2xl"}>
                    <p className={"text-xl font-bold text-secondary"}>{entry.name}</p>
                    <p className={"font-bold"}>{entry.userId.name}</p>
                    <p className={"text-lg text-secondary"}>
                        {new Date(entry.date).toLocaleDateString("de-DE", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                        })}{" "}
                        Uhr
                    </p>
                </div>

                {searchParams.questions.map(
                    (metric) =>
                        entry.hasOwnProperty(metric) && (
                            <div
                                key={metric}
                                className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                                <h2 className={"text-secondary text-md"}>{availableQuestions.find((q) => q.name === metric).displayName}</h2>
                                {availableQuestions.find((question) => question.name === metric).valueTexts && (
                                    <p>{availableQuestions.find((question) => question.name === metric).valueTexts[entry[metric]]}</p>
                                )}
                                <p>{entry[metric]}</p>
                            </div>
                        )
                )}
            </div>
        ));
    }

    return (
        <div className="m-4">
            <div className="bg-accent border rounded-2xl p-4">
                <h1 className="text-2xl text-secondary font-bold mb-3">Suche</h1>
                <form>
                    <div className="flex justify-between flex-wrap">
                        <div className={"m-2"}>
                            <h1 className="text-xl text-secondary font-bold">Athleten</h1>
                            {availableAthletes.map((athlete) => (
                                <div key={athlete._id}>
                                    <input
                                        type="checkbox"
                                        name="athletes"
                                        value={athlete._id}
                                        onChange={handleInputChange}
                                        checked={searchParams.athletes.includes(athlete._id)}
                                    />
                                    <label
                                        className={"ml-1"}
                                        onClick={() => handleLabelClick("athletes", athlete._id)}>
                                        {athlete.name}
                                    </label>
                                </div>
                            ))}
                            <input
                                type="button"
                                value="Alle auswählen"
                                onClick={() => handleSelectAll("athletes")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                            <input
                                type="button"
                                value="Auswahl löschen"
                                onClick={() => handleSelectNone("athletes")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                        </div>

                        <div className={"m-2"}>
                            <h1 className="text-xl text-secondary font-bold">Fragen</h1>
                            {availableQuestions.map((question) => (
                                <div key={question.name}>
                                    <input
                                        type="checkbox"
                                        name="questions"
                                        value={question.name}
                                        onChange={handleInputChange}
                                        checked={searchParams.questions.includes(question.name)}
                                    />
                                    <label
                                        className={"ml-1"}
                                        onClick={() => handleLabelClick("questions", question.name)}>
                                        {question.displayName}
                                    </label>
                                </div>
                            ))}
                            <input
                                type="button"
                                value="Alle auswählen"
                                onClick={() => handleSelectAll("questions")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                            <input
                                type="button"
                                value="Auswahl löschen"
                                onClick={() => handleSelectNone("questions")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                        </div>
                        <div className={"m-2"}>
                            <h1 className="text-xl text-secondary font-bold">Startdatum (optional)</h1>
                            {searchParams.startDate === undefined ? (
                                <input
                                    type="button"
                                    value="+"
                                    onClick={() => handleToggleDate("startDate")}
                                    className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                                />
                            ) : (
                                <div>
                                    <input
                                        type="date"
                                        name="startDate"
                                        value={searchParams.startDate}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        type="button"
                                        value="x"
                                        onClick={() => handleToggleDate("startDate")}
                                        className="bg-gray-200 rounded-xl mt-1 p-1"
                                    />
                                </div>
                            )}
                            <h1 className="text-xl text-secondary font-bold">Enddatum (optional)</h1>
                            {searchParams.endDate === undefined ? (
                                <input
                                    type="button"
                                    value="+"
                                    onClick={() => handleToggleDate("endDate")}
                                    className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                                />
                            ) : (
                                <div>
                                    <input
                                        type="date"
                                        name="endDate"
                                        value={searchParams.endDate}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        type="button"
                                        value="x"
                                        onClick={() => handleToggleDate("endDate")}
                                        className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <input
                        className="bg-secondary rounded-2xl mt-3 p-3 min-w-full text-accent"
                        type="button"
                        value="Suchen"
                        onClick={search}
                    />
                </form>
            </div>
            {athleteData.length > 0 && (
                <div>
                    {displayChart()}

                    <div className="bg-accent border rounded-2xl p-4 mt-4">
                        <h1 className="text-2xl text-secondary font-bold mb-3">Sortierung</h1>
                        <form className={"flex flex-wrap"}>
                            <input
                                className={
                                    sortBy === "userId"
                                        ? "mr-3 border border-primary p-2 rounded-2xl bg-primary text-accent font-bold"
                                        : "mr-3 border border-primary p-2 rounded-2xl font-bold text-dark"
                                }
                                type={"button"}
                                value={"Athlet"}
                                name={"userId"}
                                onClick={handleSortByChange}
                            />
                            <input
                                className={
                                    sortBy === "date"
                                        ? "mr-3 border border-primary p-2 rounded-2xl bg-primary text-accent text-lg"
                                        : "mr-3 border border-primary p-2 rounded-2xl text-lg text-secondary"
                                }
                                type={"button"}
                                value={"Datum"}
                                name={"date"}
                                onClick={handleSortByChange}
                            />
                        </form>
                    </div>

                    {displayTable()}
                </div>
            )}
        </div>
    );
}

export default CoachDashboard;
