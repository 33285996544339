import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
    return (
        <header className="bg-gray-800 text-white p-4 flex justify-between items-center">
            <div className="flex items-center">
                <Link
                    to="/"
                    className="text-xl mr-4">
                    <FontAwesomeIcon icon={faHome} />
                </Link>
                <Link
                    to="/"
                    className="text-xl font-bold">
                    FCS Fragebogen
                </Link>
            </div>
        </header>
    );
};

export default Header;
