import React, { useState } from "react";

const Questionnaire = ({ question, entry, handleSliderChange }) => (
    <div
        key={question.name}
        className="mb-4 border rounded-2xl bg-accent p-3">
        <h3 className={"text-md font-bold text-secondary"}>{question.question}</h3>
        <p>
            Von {question.rangeMin} ({question.valueTexts[question.rangeMin]}) bis {question.rangeMax} ({question.valueTexts[question.rangeMax]})
        </p>
        <input
            type="range"
            min={question.rangeMin}
            max={question.rangeMax}
            value={entry[question.name]}
            step={question.step}
            onChange={(e) => handleSliderChange(question.name, parseFloat(e.target.value))}
            className="w-full my-3 slider"
            style={{
                WebkitAppearance: "none",
                width: "100%",
                height: "15px",
                borderRadius: "5px",
                background: "#d3d3d3",
                outline: "none",
                opacity: 0.7,
                transition: "opacity .2s",
            }}
        />
        {entry[question.name] === -6 ? (
            <p>Bitte auswählen</p>
        ) : (
            <p>
                Deine Auswahl: {entry[question.name]} {question.valueTexts[entry[question.name]]}
            </p>
        )}
    </div>
);

const AthleteDashboard = () => {
    const [errors, setErrors] = useState("");
    const [entry, setEntry] = useState({
        readiness: -6,
        valenceDay: -6,
        rpeSession: -6,
        valenceSession: -6,
        notes: "", // Added field for notes
    });
    const [isPreSession, setIsPreSession] = useState(true);

    const questionnairePre = [
        {
            name: "valenceDay",
            question: "Wie fühlst du dich heute?",
            rangeMin: -5,
            rangeMax: 5,
            step: 1,
            valueTexts: { "-5": "sehr schlecht", 0: "neutral", 5: "sehr gut" },
        },
        {
            name: "readiness",
            question: "Wie bereit fühlst du dich heute zu trainieren?",
            rangeMin: 0,
            rangeMax: 10,
            step: 1,
            valueTexts: { 0: "überhaupt nicht bereit", 10: "maximal bereit" },
        },
    ];

    const questionnairePost = [
        {
            name: "rpeSession",
            question: "Wie hoch war die Belastung (RPE) im letzten Training/Spiel?",
            rangeMin: 1,
            rangeMax: 10,
            step: 1,
            valueTexts: { 1: "sehr niedrig", 10: "sehr hoch" },
        },
        {
            name: "valenceSession",
            question: "Wie hast du dich während der Einheit gefühlt?",
            rangeMin: -5,
            rangeMax: 5,
            step: 1,
            valueTexts: { "-5": "sehr negativ", 0: "neutral", 5: "sehr positiv" },
        },
    ];

    const handleSliderChange = (key, value) => {
        setEntry((prevEntry) => ({
            ...prevEntry,
            [key]: value,
        }));
    };

    const handleNotesChange = (e) => {
        setEntry((prevEntry) => ({
            ...prevEntry,
            notes: e.target.value,
        }));
    };

    async function handleFinishQuestionnaire() {
        try {
            if (
                (isPreSession && (entry.readiness === -6 || entry.valenceDay === -6)) ||
                (!isPreSession && (entry.rpeSession === -6 || entry.valenceSession === -6))
            ) {
                setErrors("Bitte alle Fragen beantworten.");
                return;
            }
            setErrors("");

            const response = await fetch(`/api/questionnaires`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: isPreSession
                    ? JSON.stringify({
                          readiness: entry.readiness,
                          valenceDay: entry.valenceDay,
                          notes: entry.notes,
                      })
                    : JSON.stringify({
                          rpeSession: entry.rpeSession,
                          valenceSession: entry.valenceSession,
                      }), // Send actual filled entry
            });
            if (!response.ok) {
                throw new Error("Failed to finish questionnaire");
            }
            // Show success message and reload page
            alert("Fragebogen erfolgreich abgeschlossen!");
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="m-4">
            <div>
                <h1 className="text-2xl font-bold mb-2 text-secondary">Täglicher Fragebogen</h1>
                <p className="mb-3">
                    Datum:{" "}
                    {new Date().toLocaleDateString("de-DE", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                    })}
                </p>

                <div className="flex justify-between">
                    <div
                        className="border rounded-lg border-solid bg-primary p-2 w-full text-center"
                        onClick={() => setIsPreSession(true)}>
                        Vor der Einheit/Spiel
                    </div>
                    <div
                        className="border rounded-lg border-solid bg-primary p-2 w-full text-center"
                        onClick={() => setIsPreSession(false)}>
                        Nach der Einheit/Spiel
                    </div>
                </div>
                <div>
                    <h2 className="text-lg font-bold my-2 text-secondary">Fragen</h2>
                    {isPreSession ? (
                        <div>
                            {questionnairePre.map((question) => (
                                <div key={question.name}>
                                    <Questionnaire
                                        question={question}
                                        entry={entry}
                                        handleSliderChange={handleSliderChange}
                                    />
                                </div>
                            ))}
                            <div className="mb-4 border rounded-2xl bg-accent p-3">
                                <h3 className={"text-md font-bold text-secondary pb-2"}>Notizen</h3>
                                <textarea
                                    value={entry.notes}
                                    onChange={handleNotesChange}
                                    className="w-full p-2 border rounded-md bg-white"
                                    rows={4}
                                    placeholder="Notizen eingeben..."
                                />
                            </div>
                        </div>
                    ) : (
                        questionnairePost.map((question) => (
                            <div key={question.name}>
                                <Questionnaire
                                    question={question}
                                    entry={entry}
                                    handleSliderChange={handleSliderChange}
                                />
                            </div>
                        ))
                    )}
                </div>

                <p className={"text-red-500"}>{errors}</p>
                <button
                    className={"bg-primary border rounded-md p-5 w-full"}
                    onClick={handleFinishQuestionnaire}>
                    Questionnaire abschließen
                </button>
            </div>
        </div>
    );
};

export default AthleteDashboard;
