import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import Dashboard from "./pages/DashboardPage/Dashboard";
import RenewPasswordPage from "./pages/LoginPage/RenewPasswordPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    exact
                    path={"/"}
                    element={<Dashboard />}
                />
                <Route
                    exact
                    path={"/login"}
                    element={<LoginPage />}
                />
                <Route
                    exact
                    path={"/new-password"}
                    element={<RenewPasswordPage />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
